<template>
  <v-col align="center" class="mt-n2" v-if="tasks.length == 0" cols="12">
    <v-btn @click="openNewTaskDialog()" color="success">
      <v-icon>mdi-plus</v-icon>Add Task
    </v-btn>
  </v-col>
  <v-card v-bind="props" rounded="xl" v-else>
    <v-card-title>Tasks</v-card-title>
    <v-row class="pa-10">
      <v-col
        :xxl="entityInfo ? '6' : '3'"
        :xl="entityInfo ? '6' : '3'"
        :lg="entityInfo ? '12' : '4'"
        :md="entityInfo ? '12' : '4'"
        :sm="entityInfo ? '12' : '6'"
        :xs="entityInfo ? '12' : '12'"
        :cols="entityInfo ? '12' : '12'"
        v-for="task in tasks"
      >
        <v-hover v-slot="{ isHovering, props }">
          <v-card
            @click="openTaskDialog(task)"
            v-bind="props"
            rounded="xl"
            :variant="isHovering ? 'outlined' : 'elevated'"
            elevation="4"
          >
            <v-card-text>
              <v-row>
                <v-col cols="8">
                  <div>
                    <strong>{{ task.title }}</strong>
                  </div>
                </v-col>
                <v-col cols="4" align="right">
                  <v-tooltip
                    top
                    :text="userObject[listener].full_name"
                    v-for="listener in task.listeners"
                  >
                    <template v-slot:activator="{ props }">
                      <v-avatar v-bind="props" color="indigo" size="small">{{
                        userObject[listener].initials
                      }}</v-avatar>
                    </template>
                  </v-tooltip>
                </v-col>
                <v-col cols="6" align="left" class="mt-n4">
                  {{
                    task.public_users ? task.public_users.full_name : "WRONG"
                  }}
                </v-col>
                <v-col cols="6" align="right" class="mt-n4">
                  <v-icon>mdi-calendar</v-icon>
                  <span class="text-caption">{{ task.showDueDate }}</span>
                </v-col>
                <v-col cols="6" class="mt-n4" align="left">
                  <template v-if="task.employee_id && !entity">
                    <v-icon>mdi-account-group</v-icon>
                    <router-link
                      :to="'/Mitarbeiter/' + task.employee_id"
                      target="_blank"
                      ><strong>{{
                        employeeObject[task.employee_id].name
                      }}</strong></router-link
                    >
                  </template>
                  <template v-else-if="task.candidate_id && !entity">
                    <router-link
                      target="_blank"
                      :to="'/Kandidaten/' + task.candidate_id"
                      ><v-icon>mdi-account-search</v-icon
                      ><strong>{{
                        task.recruiting_candidates.first_name +
                        " " +
                        task.recruiting_candidates.last_name
                      }}</strong></router-link
                    >
                  </template>
                  <strong v-else-if="!entityId">General</strong>
                </v-col>
                <v-col
                  cols="6"
                  align="right"
                  class="mt-n4"
                  v-if="task.task_comments.length > 0"
                >
                  <v-badge :content="task.task_comments.length" color="red">
                    <v-icon
                      icon="mdi-chat"
                      size="x-large"
                      color="teal-darken-2"
                    ></v-icon>
                  </v-badge>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
      <v-col align="center" class="mt-n2" cols="12">
        <v-btn @click="openNewTaskDialog()" color="success">
          <v-icon>mdi-plus</v-icon>Add Task
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
  <v-dialog v-model="taskDialog" width="700">
    <v-card color="grey-lighten-4">
      <v-card-text>
        <v-card variant="outlined"
          ><v-card-text>
            <v-row>
              <v-col cols="10">
                <v-text-field
                  label="Titel"
                  variant="solo"
                  v-model="commentMode.task.title"
                ></v-text-field>
              </v-col>
              <v-col cols="2" align="right">
                <v-icon @click="taskDialog = false">mdi-close</v-icon>
              </v-col>
              <v-col cols="12" class="mt-n6">
                <v-textarea
                  v-model="commentMode.task.note"
                  variant="solo"
                  label="Beschreibung"
                  rows="3"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="mt-n6">
                <v-autocomplete
                  label="Bearbeiter"
                  :items="activeUsers"
                  item-title="full_name"
                  item-value="id"
                  v-model="commentMode.task.listeners"
                  multiple
                  clearable
                  variant="solo"
                  chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="mt-n6">
                <v-autocomplete
                  label="Mitarbeiter"
                  :items="employees"
                  item-value="id"
                  item-title="name"
                  variant="solo"
                  clearable
                  v-model="commentMode.task.employee_id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="mt-n6">
                <v-autocomplete
                  label="Kandidaten"
                  :items="candidates"
                  item-value="id"
                  item-title="name"
                  variant="solo"
                  clearable
                  v-model="commentMode.task.candidate_id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="mt-n6">
                <v-text-field
                  label="Fällig am"
                  type="datetime-local"
                  density="compact"
                  v-model="commentMode.task.due_date"
                  variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="mt-n6" align="left">
                <v-btn
                  :disabled="!commentMode.task.title"
                  color="success"
                  @click="saveTask(commentMode.task)"
                  :loading="loading"
                  >Speichern</v-btn
                >
              </v-col>
              <v-col cols="6" class="mt-n6" align="right">
                <v-btn
                  prepend-icon="mdi-check-circle"
                  :disabled="!commentMode.task.id"
                  color="blue-darken-2"
                  @click="completeTask(commentMode.task)"
                  >Aufgabe abschließen</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text></v-card
        >
        <v-divider class="my-4"></v-divider>
        <div v-if="commentMode.task.id">
          <div class="text-h5 my-4">Kommentare</div>
          <v-textarea
            v-model="commentMode.object.text"
            label="Schreiben Sie einen Kommentar"
            variant="solo"
            rows="3"
          ></v-textarea>
          <v-btn
            :disabled="!commentMode.object.text"
            class="mt-n4 mb-6"
            @click="addComment(commentMode, commentMode.object, task)"
            color="indigo"
            >Speichern</v-btn
          >
          <v-row>
            <v-col cols="12" v-for="comment in commentMode.task.task_comments">
              <div>
                <strong class="mr-2">{{
                  comment.public_users.full_name
                }}</strong>
                {{
                  new Date(comment.created_at).toLocaleDateString("de-DE", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                }}
              </div>
              <v-card>
                <v-card-text>
                  <div>{{ comment.text }}</div>
                  <div></div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
const props = defineProps(["entityId", "entity", "entityInfo"]);
</script>
<script>
export default {
  data() {
    return {
      tasks: [],
      userId: null,
      ownerId: null,
      users: [],
      taskDialog: false,
      loading: false,
      editMode: false,
      commentMode: {
        object: {},
        task: {},
        fixedText: true,
      },
      employees: [],
      employeeObject: {},
      candidates: [],
      candidateObject: {},
      mobile: false,
      activeUsers: [],
      userObject: {},
    };
  },
  methods: {
    async openNewTaskDialog() {
      var now = new Date();
      now.setHours(now.getHours() + 3);
      this.commentMode = {
        object: {},
        task: {
          owner_id: this.userId,
          todo_owner_id: this.userId,
          due_date: now.toISOString().slice(0, 16),
        },
        fixedText: false,
      };
      this.taskDialog = true;
      console.log(this.taskDialog);
      if (this.entity) {
        this.commentMode.task[this.entity] = this.entityId;
      }
    },
    async completeTask(task) {
      var queryObject = {
        table: "tasks",
        id: task.id,
        payload: {
          is_completed: true,
          finished_at: new Date().toISOString(),
          modified_at: new Date().toISOString(),
        },
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);

      var queryObject = {
        table: "notes",
        payload: {
          owner_id: this.$store.state.userInfo.id,
          action: `Task "${task.title}" wurde abgehakt!`,
          text: task.note,
        },
      };
      if (task.employee_id) {
        queryObject.payload.employee_id = task.employee_id;
      } else if (task.candidate_id) {
        queryObject.payload.candidate_id = task.candidate_id;
      }
      await this.$store.dispatch("createSupabaseEntity", queryObject);
      this.$store.state.employeeChangeCheck = true;
      this.taskDialog = false;
      this.$store.dispatch("triggerAppBarTaskUpdate");
      this.getTasks();
    },
    async getEmployees() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employees",
        select: "first_name,last_name,id",
        where: [{ type: "eq", key: "status", value: "Aktiv" }],
        order: { key: "last_name", ascending: true },
      });
      response.data.forEach((item) => {
        item.name = item.last_name + ", " + item.first_name;
        this.employeeObject[item.id] = item;
      });
      this.employees = response.data;
    },
    async getCandidates() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "recruiting_candidates",
        select: "first_name,last_name,id,recruiting_job_candidates!inner(*)",
        where: [
          {
            type: "in",
            key: "recruiting_job_candidates.status_id",
            value: [1, 2, 3, 4, 5, 6, 9, 11, 12, 19],
          },
          { type: "neq", key: "recruiting_job_candidates.job_id", value: 4 },
          { type: "neq", key: "last_name", value: "MERGED" },
        ],
        order: { key: "last_name", ascending: true },
      });
      response.data.forEach((item) => {
        item.name = item.last_name + ", " + item.first_name;
        this.candidateObject[item.id] = item;
      });
      this.candidates = response.data;
    },
    async getTasks() {
      var checkDate = new Date();
      checkDate.setDate(checkDate.getDate() + 7);
      var today = new Date();
      today.setHours(1);
      var queryObject = {
        select:
          "*,task_comments(*,public_users(full_name)),recruiting_candidates(first_name,last_name),recruiting_jobs(title),employees(name),public_users!tasks_todo_owner_id_fkey(full_name)",
        table: "tasks",
        where: [
          {
            type: "or",
            key: "owner_id",
            value: `owner_id.eq.${this.userId},listeners.cs.{${this.userId}}`,
          },
          { type: "is", key: "is_completed", value: false },
        ],
        order: { key: "due_date" },
      };
      if (this.entity) {
        queryObject.where.push({
          type: "eq",
          key: this.entity,
          value: this.entityId,
        });
      } else {
        queryObject.where.push({
          type: "lte",
          key: "due_date",
          value: checkDate.toISOString(),
        });
      }
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      var users = await this.$store.dispatch("getUsers");
      for (var i = 0; i < response.data?.length; i++) {
        var item = response.data[i];
        var date = response.data[i].due_date;
        response.data[i].task_comments.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        response.data[i].owner = users.find(
          (userItem) => userItem.id == item.owner_id,
        ).full_name;
        response.data[i].due =
          new Date(date).getTime() < new Date(today).getTime();
        if (date) {
          response.data[i].showDueDate = new Date(date).toLocaleString(
            "de-DE",
            { timeZone: "UTC" },
          );
          response.data[i].due_date = response.data[i].due_date.split("+")[0];
        }
        if (item.candidate_id) {
          item.entity = "Candidate";
          item.icon = "mdi-account-group";
          item.color = "indigo-darken-3";
        } else if (item.company_id) {
          item.entity = "Company";
          item.icon = "mdi-domain";
          item.color = "orange-darken-3";
        } else if (item.partner_id) {
          item.entity = "Partner";
          item.icon = "mdi-account-tie";
          item.color = "green-darken-4";
        } else if (item.job_id) {
          item.entity = "Job";
          item.icon = "mdi-briefcase";
          item.color = "red-darken-3";
        } else if (item.pool_id) {
          item.entity = "Pool";
          item.icon = "mdi-account-search";
          item.color = "purple-darken-3";
        } else {
          item.entity = "General";
          item.icon = "mdi-clipboard";
          item.color = "blue-grey-darken-2";
        }
      }
      this.tasks = response.data;
    },
    async openTaskDialog(task) {
      this.commentMode.task_id = task.id;
      await this.updateComments(this.commentMode, task);
      this.commentMode.object = {
        owner_id: this.$store.state.userInfo.id,
        task_id: task.id,
      };
      this.commentMode.task = {};
      this.commentMode.task = task;
      this.taskDialog = true;
      console.log(this.taskDialog);
    },
    async saveTask(task) {
      this.loading = true;
      let entity = this.entity;
      let entityId = this.entityId;
      if (task.employee_id) {
        entity = "employee_id";
        entityId = task.employee_id;
      } else if (task.candidate_id) {
        entity = "candidate_id";
        entityId = task.candidate_id;
      }
      var taskDuplicate = JSON.parse(JSON.stringify(task));
      if (entity) {
        taskDuplicate[entity] = entityId;
      }
      var payload = JSON.parse(JSON.stringify(taskDuplicate));
      delete payload.edit;
      delete payload.color;
      delete payload.due;
      delete payload.task_comments;
      delete payload.entity;
      delete payload.icon;
      delete payload.public_users;
      delete payload.recruiting_candidates;
      delete payload.recruiting_jobs;
      delete payload.showDueDate;
      delete payload.owner;
      delete payload.employees;
      if (taskDuplicate.id) {
        var queryObject = {
          table: "tasks",
          id: taskDuplicate.id,
          payload: payload,
        };
        payload.modified_at = new Date().toISOString();
        await this.$store.dispatch("updateSupabaseEntity", queryObject);
      } else {
        var queryObject = {
          table: "tasks",
          payload: payload,
        };
        var id = await this.$store.dispatch(
          "createSupabaseEntity",
          queryObject,
        );
        if (entity) {
          var queryObject = {
            table: "notes",
            payload: {
              owner_id: this.$store.state.userInfo.id,
              action: `Task "${payload.title}" erstellt`,
              text: payload.note,
              role_id: 1,
            },
          };
          queryObject.payload[entity] = entityId;
          console.log(payload);
          await this.$store.dispatch("createSupabaseEntity", queryObject);
          this.$store.state.candidateChangeCheck = true;
          this.$store.state.employeeChangeCheck = true;
        }
        task.id = id;
      }
      this.$store.dispatch("triggerAppBarTaskUpdate");
      this.getTasks();
      this.loading = false;
    },
    async addComment(commentMode, comment) {
      if (!comment.task_id) {
        comment.task_id = commentMode.task.id;
        commentMode.task_id = commentMode.task.id;
        comment.owner_id = this.$store.state.userInfo.id;
      }
      await this.$store.dispatch("upsertSupabaseEntity", {
        table: "task_comments",
        payload: comment,
      });
      this.updateComments(commentMode);
      comment.text = null;
    },

    async updateComments(commentMode) {
      var queryObject = {
        select: "*,public_users(full_name)",
        table: "task_comments",
        where: [{ type: "eq", key: "task_id", value: commentMode.task_id }],
        order: { key: "created_at", ascending: false },
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      commentMode.task.task_comments = response.data;
      this.getTasks();
    },
    async closeEditMode(task) {
      task.edit = false;
      this.editMode = false;
      if (!task.id) {
        this.tasks.pop();
      }
    },
  },
  async created() {
    var users = await this.$store.dispatch("getUsers");
    var userObject = {};
    users.forEach(function (item) {
      if (item.full_name) {
        item.initials = item.full_name
          .split(" ")
          .map((n) => n[0])
          .join("");
      }

      userObject[item.id] = item;
    });
    this.userObject = userObject;
    this.users = users;
    this.activeUsers = this.users.filter((item) => item.active);
    var response = await this.$store.dispatch("getSupabaseEntity", {
      table: "get_office_users",
    });
    this.activeUsers = response.data;
    for (var i = 0; i < users.length; i++) {
      if (users[i].full_name == this.$store.state.chosenUser) {
        this.userId = users[i].id;
      }
      if (users[i].email == this.$store.state.username) {
        this.ownerId = users[i].id;
      }
    }
    this.userId = this.$store.state.userInfo.id;
    this.ownerId = this.$store.state.userInfo.id;
    //this.userId = 5

    this.getTasks();
    this.getEmployees();
    this.getCandidates();
  },
};
</script>
