<template>
  <v-row class="ma-1">
    <v-col class="text-h4" cols="12"> ToDo Mitarbeiterchecks </v-col>
    <v-col cols="12">
      <v-btn
        v-for="city in cityFilter"
        @click="city.chosen = !city.chosen"
        :color="city.chosen ? 'indigo' : 'grey'"
        class="ma-1"
        >{{ city.city }}</v-btn
      >
    </v-col>
    <template v-for="toCheck in checklist">
      <v-col
        v-if="checkListItemCheck(toCheck)"
        xxl="3"
        xl="3"
        lg="4"
        md="6"
        sm="6"
        xs="6"
        cols="12"
      >
        <v-card elevation="5">
          <v-card-text>
            <v-row>
              <v-col cols="8">
                <div>{{ toCheck.name }}</div>
                <div class="text-caption">
                  Heute im
                  <b>{{ toCheck.hotel + " / " + toCheck.hotel_city }}</b>
                </div>
                <v-chip
                  @click="router.push(`employeeCheck/${check.id}`)"
                  variant="elevated"
                  size="x-small"
                  v-for="check in toCheck.month_checks"
                  :color="check.is_passed ? 'success' : 'error'"
                  >{{
                    `Check am ${new Date(check.date).toLocaleDateString(
                      "de",
                    )} durchgeführt`
                  }}</v-chip
                >
              </v-col>
              <v-col cols="4" align="right">
                <v-avatar
                  v-if="toCheck.score == 0"
                  class="ma-2"
                  size="40"
                  color="success"
                  ><v-icon>mdi-check</v-icon></v-avatar
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </v-row>
  <v-row>
    <v-data-table
      :items="checks"
      :items-per-page="-1"
      class="ma-12"
      :headers="headers"
    >
      <template v-slot:item.employee="{ item }">
        <router-link :to="'/Mitarbeiter/' + item.id">
          {{ item.employee }}</router-link
        >
      </template>
      <template v-slot:item.checks="{ item }">
        <v-chip
          variant="elevated"
          v-for="check in item.checks"
          size="small"
          :color="check.is_passed ? 'success' : 'error'"
          @click="openEmployeeCheck(check)"
        >
          {{ check.hotels.short_name }} -
          {{ new Date(check.date).toLocaleDateString("de") }}
        </v-chip>
      </template>
    </v-data-table>
  </v-row>
  <v-dialog v-model="employeeCheck.dialog">
    <v-card>
      <EmployeeCheck
        v-if="employeeCheck.dialog"
        :employeeCheckProp="employeeCheck.employeeCheck"
        @closeDialog="closeEmployeeCheckDialog"
      ></EmployeeCheck>
    </v-card>
  </v-dialog>
</template>

<script setup>
import EmployeeCheck from "@/components/Employees/ProfileComponents/Performance/EmployeeCheck.vue";

import { onMounted, ref } from "vue";
import { useStore } from "vuex";

var store = useStore();
let checks = ref([]);
let checkArray = ref([]);
let checklist = ref([]);
let hotelFilter = ref([]);
let chosenHotel = ref(null);
let cityFilter = ref([]);
let headers = ref([
  { title: "Mitarbeiter", key: "employee", value: "employee" },
  { title: "Checks", key: "checks", value: "checks" },
]);
let employeeCheck = ref({
  dialog: false,
  employeeCheck: {},
});

const closeEmployeeCheckDialog = () => {
  employeeCheck.value.dialog = false;
};

const getToCheckList = async () => {
  var employeeChecks = [];
  var employeeObject = {};
  const today = new Date();
  const dateMonth = today.getMonth();
  const employeeResponse = await store.dispatch("getSupabaseEntity", {
    select: "id,name",
    table: "employees",
  });
  console.log(employeeResponse);
  employeeResponse.data.forEach((employee) => {
    employeeObject[employee.id] = employee;
  });
  const response = await store.dispatch("getSupabaseEntity", {
    table: "view_check_employee_forms",
  });
  let hotelFilterSet = new Set();
  let cityFilterSet = new Set();
  console.log(response.data);
  response.data.forEach((employee) => {
    employee.month_checks = employee.month_checks.filter(
      (check) => new Date(check.date).getMonth() == dateMonth,
    );
    employee.name = employeeObject[employee.employee_id].name;
    if (
      employee.month_checks.length == 2 ||
      (employee.month_checks.length == 1 &&
        employee.employment_type != "Festanstellung")
    ) {
      employee.score = 0;
    }
    if (
      (employee.month_checks.length == 1 &&
        employee.employment_type == "Festanstellung") ||
      (employee.month_checks.length == 0 &&
        employee.employment_type != "Festanstellung")
    ) {
      employee.score = 1;
    }
    if (
      employee.month_checks.length == 0 &&
      employee.employment_type == "Festanstellung"
    ) {
      employee.score = 2;
    }
    hotelFilterSet.add(employee.hotel);
    cityFilterSet.add(employee.hotel_city);
  });
  hotelFilter.value = Array.from(hotelFilterSet);
  for (const x of cityFilterSet) {
    cityFilter.value.push({ city: x, chosen: true });
  }

  response.data.sort((a, b) => {
    if (a.score > b.score) {
      return -1;
    }
    if (a.score < b.score) {
      return 1;
    }
    return 0;
  });
  checklist.value = response.data;
  console.log(checklist);
};

const openEmployeeCheck = async (check) => {
  const response = await store.dispatch("getSupabaseEntity", {
    select:
      "*,hotels(name,short_name,city),employee:employees!employee_check_form_employee_id_fkey(name),inspector:employees!employee_check_form_inspector_id_fkey(name)",
    table: "employee_check_form",
    where: [{ type: "eq", key: "id", value: check.id }],
  });
  let checkObject = response.data[0];
  checkObject.hotel = check.hotels.name;
  checkObject.employee = check.employee.name;
  checkObject.inspector = check.inspector.name;
  employeeCheck.value.employeeCheck = checkObject;
  employeeCheck.value.dialog = true;
};

const checkListItemCheck = (toCheck) => {
  const scoreCheck = toCheck.score >= 0;
  let cityCheck = false;
  cityFilter.value.forEach((city) => {
    if (city.chosen && city.city == toCheck.hotel_city) {
      cityCheck = true;
    }
  });
  return scoreCheck && cityCheck;
};

const getEmployeeChecks = async () => {
  checks.value = [];
  const response = await store.dispatch("getSupabaseEntity", {
    select:
      "id,employee_id,date,is_signed,is_passed,hotels(name,short_name,city),employee:employees!employee_check_form_employee_id_fkey(name),inspector:employees!employee_check_form_inspector_id_fkey(name)",
    table: "employee_check_form",
    where: [{ type: "is", key: "is_template", value: false }],
  });
  var employeeObject = {};
  var today = new Date();
  for (var i = 0; i < 3; i++) {
    //headers.value.push({ title: today.getMonth() + "." + today.getFullYear(), key: today.getMonth() + "." + today.getFullYear(), value:today.getMonth() + "." + today.getFullYear()});
    today.setMonth(today.getMonth() + 1);
  }
  response.data.forEach((item) => {
    if (!employeeObject[item.employee_id]) {
      employeeObject[item.employee_id] = {
        id: item.employee_id,
        employee: item.employee.name,
        checks: [],
      };
    }
    employeeObject[item.employee_id].checks.push(item);
  });
  var keys = Object.keys(employeeObject);
  for (var i = 0; i < keys.length; i++) {
    employeeObject[keys[i]].checks.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    checks.value.push(employeeObject[keys[i]]);
  }
};

onMounted(async () => {
  await getEmployeeChecks();
  getToCheckList();
});
</script>
