<template>
  <v-card>
    <v-btn color="indigo" class="ma-12" @click="prepareEmail()"
      >Email Dialog öffnen</v-btn
    >
    <EmailDialog
      :receivers="receivers"
      v-if="$store.state.emailDialog"
      :entityInfo="receivers"
      :chosenEmail="chosenEmail"
      entity="employees"
      :portalEmails="portalEmails"
    ></EmailDialog>
    <v-card-text>
      <v-row>
        <v-col align="right" class="mr-9">
          <v-btn
            class="mx-2"
            rounded="lg"
            color="blue-darken-2"
            v-for="all in allFilter"
            @click="checkAll(all)"
            >{{ all.title }}</v-btn
          >
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="receivers"
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:item.to="{ item }">
          <v-icon
            @click="handleCheck(item, 'to', item.to)"
            :icon="
              item.to ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
            "
          ></v-icon>
        </template>
        <template v-slot:item.cc="{ item }">
          <v-icon
            @click="handleCheck(item, 'cc', item.cc)"
            :icon="
              item.cc ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
            "
          ></v-icon>
        </template>
        <template v-slot:item.bcc="{ item }">
          <v-icon
            @click="handleCheck(item, 'bcc', item.bcc)"
            :icon="
              item.bcc ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
            "
          ></v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import EmailDialog from "../CommonComponents/EmailComponent/EmailDialog.vue";
const props = defineProps({
  employees: { type: Array, required: true },
});
</script>

<script>
export default {
  data() {
    return {
      emailDialog: false,
      portalEmails: [],
      chosenEmail: {
        portal: true,
        subject: null,
        to: [],
        cc: [],
        bcc: [],
      },
      receivers: [],
      allFilter: [
        { title: "Keine", value: "none" },
        { title: "To", value: "to" },
        { title: "Cc", value: "cc" },
        { title: "Bcc", value: "bcc" },
      ],
      headers: [
        { title: "Vorname", value: "first_name", key: "first_name" },
        { title: "Nachname", value: "last_name", key: "last_name" },
        { title: "Email", value: "email", key: "email" },
        { title: "Position", value: "position", key: "position" },
        { title: "Status", value: "status", key: "status" },
        { title: "To", value: "to", key: "to" },
        { title: "Cc", value: "cc", key: "cc" },
        { title: "Bcc", value: "bcc", key: "bcc" },
      ],
    };
  },
  methods: {
    prepareEmail() {
      this.chosenEmail.subject = null;
      this.chosenEmail.from = "portal@bellcaptain.team";
      this.chosenEmail.friendlyName = "Bellcaptain Team";
      this.chosenEmail.to = [];
      this.chosenEmail.cc = [];
      this.chosenEmail.bcc = [];
      this.receivers.forEach((receiver) => {
        if (receiver.to) {
          this.chosenEmail.to.push(receiver.email);
        }
        if (receiver.cc) {
          this.chosenEmail.cc.push(receiver.email);
        }
        if (receiver.bcc) {
          this.chosenEmail.bcc.push(receiver.email);
        }
      });
      this.$store.state.emailDialog = true;
    },
    handleCheck(item, type, value) {
      item.to = false;
      item.cc = false;
      item.bcc = false;
      item[type] = !value;
    },
    checkAll(all) {
      this.receivers.forEach((receiver) => {
        receiver.to = false;
        receiver.cc = false;
        receiver.bcc = false;
        if (all.value !== "none") {
          receiver[all.value] = true;
        }
      });
    },
  },
  async created() {
    this.portalEmails = (
      await this.$store.dispatch("getSupabaseEntity", {
        table: "email_groups",
        where: [{ type: "is", key: "use_frontend", value: true }],
      })
    ).data;
    this.receivers = JSON.parse(JSON.stringify(this.employees));
    this.receivers.forEach((receiver) => {
      receiver.to = false;
      receiver.cc = false;
      receiver.bcc = true;
    });
  },
};
</script>
